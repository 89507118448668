* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

body {
    font-size: 20px;
    font-family: 'Mulish', sans-serif;
}

:root {
    --white: #fff;
    --gold: #C90;
    --black: #000;
    --beige: #F6EEE1;
    --grey: #525252;
    --cormorant: 'Cormorant Garamond', serif;
    --beige2: #DCCEB4;
    --brown: #96593C;
}

a {
    text-decoration: none;
}

.header_nav li {
    list-style: none;
}

.header_nav_left {
    display: flex;
    gap: 39px;
}

header {
    width: 100%;
    height: 100vh;
    background-image: url('../img/8879686\ 3.png');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}

.shadow {
    width: 100%;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.363);
}

.header_top_container {
    display: flex;
    justify-content: space-between;
}

.logo {
    width: 104px;
    height: 104px;
    margin-top: 21px;
    margin-left: 136px;
}

.logo img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}


.header_nav {
    display: flex;
    gap: 39px;
    align-items: center;
    margin-top: 33px;
    margin-right: 134px;
}

.nav_left {
    display: flex;
    align-items: center;
    gap: 23px;
}

.nav_left li a {
    color: var(--white);
    font-size: 90%;
    font-weight: 500;
}

.nav_left span {
    color: var(--white);
}

#resp_check {
    display: none;
}

#respIcon {
    display: none;
}

#checkbox {
    display: none;
}

.market {
    padding: 14px 16px;
    border-radius: 99px;
    border: 1px solid #FFF;
}

.market a {
    color: white;
    font-size: 90%;
    font-weight: 500;
}


.header_main_container {
    display: flex;
    justify-content: space-between;
    margin-top: 8vh;
    align-items: center;
    gap: 40px;
}

.sm_circle {
    width: 46px;
    height: 46px;
    border: 2px solid var(--white);
    border-radius: 100%;
    text-align: center;
    padding: 9px 11px;
}

.sm_circle img {
    width: 100%;
    height: 100%;
}

.sm_container {
    display: flex;
    gap: 18px;
    align-items: center;
    margin-top: 70px;
}

.header_main_left {
    max-width: 582px;
    margin-left: 137px;
}

.header_main_left h1 {
    font-size: 275%;
    font-weight: 700;
    font-family: 'Cormorant Garamond', serif;
    color: var(--white);
    /* line-height: 60px; */
}

.header_main_left p {
    margin-top: 17px;
    font-size: 100%;
    font-weight: 400;
    color: var(--white);
}

.header_btns {
    display: flex;
    gap: 22px;
    margin-top: 24px;
    align-items: start;
    flex-direction: column;
}

.header_btns button:nth-child(1) {
    padding: 11px 16px;
    border-radius: 3px;
    background: #FFF;
    border: none;
    font-family: 'Mulish', sans-serif;
    color: var(--grey);
    font-size: 80%;
    font-weight: 600;
}

.header_btns button:nth-child(2) {
    padding: 11px 10px;
    background: none;
    border-radius: 3px;
    border: 1px solid #FFF;
    font-family: 'Mulish', sans-serif;
    color: var(--white);
    font-size: 80%;
    font-weight: 500;
}

.header_main_right {
    width: 391px;
    height: 538px;
    border: 5px solid #FFF;
    margin-right: 150px;
}

.header_main_right img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
    margin-left: 30px;
    margin-top: 25px;
}

.container {
    max-width: 1170px;
    margin-left: auto;
    margin-right: auto;
}

.section_header {
    text-align: center;
    margin-top: 85px;
    color: var(--gold);
    font-family: 'Cormorant Garamond', serif;
    font-size: 250%;
    font-weight: 700;
}

.flex_container_525 {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(525px, 1fr));
    gap: 116px;
    margin-top: 60px;
}



.flex_card_525 {
    max-width: 525px;
    height: auto;
    padding: 17px 20px;
    border-radius: 6px;
    background: #F6EEE1;
    box-shadow: 0px 0px 9px 0px rgba(0, 0, 0, 0.18);
}

.flex_card_525 p:nth-child(1) {
    width: 406px;
    font-size: 100%;
    font-weight: 400;
    /* line-height: 30px */
}

.flex_card_525 p:nth-child(2),
.flex_card_525 p:nth-child(4) {
    font-weight: 700;
}

.main_left {
    width: 197px;
    height: 197px;
    background-image: url('../img/leftStar.png');
    background-repeat: no-repeat;
    background-size: contain;
    background-position: left;
}

.main_container {
    display: flex;
    justify-content: space-between;
    position: relative;
}

.flex_container_main {
    position: absolute;
    z-index: 9999;
    margin-left: auto;
    margin-right: auto;
    left: 135px;
    right: 135px;
    max-width: 1170px;
    display: flex;
    justify-content: space-between;
}

.main_right {
    width: 197px;
    height: 197px;
    background-image: url('../img/rightStar.png');
    background-repeat: no-repeat;
    background-size: contain;
    background-position: right;
    margin-top: 90vh;
}

.flex_container_main {
    max-width: 1170px;
    display: flex;
    justify-content: space-between;
    gap: 70px;
    margin-top: 120px;

}

.flex_right_img {
    width: 601px;
    height: 890px;
    border-radius: 6px 6px 0 0;
}

.flex_right_img img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
    border-radius: 6px 6px 0 0;
}


.flex_container_right {
    margin-top: 40px;
    max-width: 601px;
    border-radius: 6px;
    background: #FFF;
    box-shadow: 0px 0px 9px 0px rgba(0, 0, 0, 0.18);
}

.flex_container_right p {
    padding: 10px;
    color: #8B5002;
    font-size: 90%;
    font-weight: 600;
    /* line-height: 28px; */
}

.description_card {
    max-width: 509px;
    border-bottom: 0.5px solid rgba(82, 82, 82, 0.30);
    padding-bottom: 29px;
    display: flex;
    align-items: center;
    gap: 18px;
    margin-top: 23px;
}

.description_card h3 {
    color: var(--gold);
    font-family: var(--cormorant);
    font-size: 250%;
    font-weight: 300;
}

.description_card p {
    font-size: 90%;
    font-weight: 500;
    margin-top: 5px;
}

.desc_card_type_2 {
    border-left: 0.5px solid #777;
    border-bottom: 0.5px solid #777;
    margin-top: 107px;
    padding-left: 16px;
    padding-bottom: 8px;
}

.desc_card_type_2 p {
    /* line-height: 28px; */
    font-size: 90%;
    font-weight: 400;
}

.desc_card_type_2 p span {
    display: block;
    max-width: 300px;
    font-weight: 700;
    /* line-height: 28px; */
}


.dashboard_end {
    display: flex;
    justify-content: space-between;
    margin-top: 40%;
    align-items: center;
}

.dashboard_end h2 {
    max-width: 509px;
    font-size: 275%;
    font-weight: 900;
    color: var(--beige2);
    /* line-height: 66px; */
}

.dashboard_end img {
    width: 410px;
    height: 425px;
    object-fit: cover;
    margin-right: 82px;
}

footer {
    background-color: #E5E5E5;
    padding-top: 23px;
    padding-bottom: 30px;
    margin-top: 100px;
}

footer h3 {
    text-align: center;
    margin-top: 20px;
    font-size: 100%;
    font-weight: 800;
    color: #525252;
}

.time {
    text-align: center;
    /* line-height: 30px; */
    font-size: 80%;
    font-weight: 400;
}


.footer_container {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.footer_logo {
    margin-left: 142px;
}

.footer_container h3 {
    text-align: center;
    font-size: 100%;
    font-weight: 900;
    color: #6F6F6F;
}

.footer_container p {
    font-size: 80%;
    font-weight: 400;
    width: 315px;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    margin-top: 23px;
    color: #6F6F6F;
}

.sm_container_footer {
    display: flex;
    gap: 15px;
    margin-right: 135px;
    align-items: center;
}

.footer_circle {
    width: 36px;
    height: 36px;
    padding: 6px;
    border: 1.5px solid #6F6F6F;
    border-radius: 100%;
    text-align: center;
}

.footer_circle img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}


/* About Page */

.header_nav_about li a,
.header_nav_about span {
    color: var(--gold);
}

.market_ab {
    padding: 14px 16px;
    border-radius: 99px;
    border-radius: 99px;
    border: 1px solid #C90;
}

.about_me_flex {
    display: flex;
    justify-content: space-between;
    gap: 25px;
    margin-top: 79px;
}

.about_me_img {
    width: 570px;
    height: 644px;
    border-radius: 9px;
}

.about_me_img img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
    border-radius: 9px;
}

.about_text_container {
    position: relative;
    max-width: 576px;
    height: auto;
    border-radius: 6px;
    background: #FFF;
    box-shadow: 0px 0px 9px 0px rgba(0, 0, 0, 0.18);
    padding: 27px 20px 30px 35px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.about_text h3 {
    font-size: 150%;
    font-weight: 700;
    color: var(--brown);
    font-family: var(--cormorant);
}



.page_change {
    width: 60%;
    margin-left: auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.grid_container_377 {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(575px, 1fr));
    gap: 20px;
    margin-top: 60px;
}

.grid_card {
    border-radius: 6px;
    background: #FFF;
    box-shadow: 0px 0px 9px 0px rgba(0, 0, 0, 0.17);
    padding: 23px 30px 20px 30px;
    text-align: center;
    height: 460px;
}

.grid_card_img {
    margin-left: auto;
    margin-right: auto;
    width: 168px;
    height: 168px;
    border: 0.5px solid #CC9900;
    border-radius: 100%;
    text-align: center;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.16);
}

.grid_card p {
    font-size: 100%;
    color: #525252;
    font-weight: 800;
    margin-top: 22px;
    /* line-height: 35px; */
}

/* Market */


.filter_heading h3 {
    font-size: 90%;
    font-weight: 600;
    border-bottom: 1px solid rgba(0, 0, 0, 0.35);
    padding-bottom: 15px;
    width: 198px;
    margin-top: 15px;
}

.filters label {
    display: flex;
    gap: 19px;
    align-items: center;
    margin-top: 14px;
}

.filters {
    border-bottom: 1px solid rgba(0, 0, 0, 0.35);
    padding-bottom: 15px;
    width: 198px;
}

.filter_left_container p {
    font-size: 80%;
    font-weight: 700;
    margin-top: 13px;
}

.active_filter {
    font-size: 80%;
    font-weight: 400;
}

.passive_filter {
    font-size: 80%;
    font-weight: 400;
    color: #8C8C8C;
}


.products_container_277 {
    max-width: 873px;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(277px, 1fr));
    gap: 20px;
    align-items: start;
}

.product_card_277 {
    min-width: 277px;
    height: 450px;
    background: #FFF;
    box-shadow: 0px 0px 9px 0px rgba(0, 0, 0, 0.18);
    padding-bottom: 13px;
    position: relative;
}

.product_img {
    position: relative;
    height: 215px;
}

.product_img img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.prod_circle {
    width: 24px;
    height: 24px;
    border: 1px solid #C90;
    border-radius: 100%;
    position: absolute;
    top: 10px;
    right: 10px;
    text-align: center;
    padding: 4px;
}

.prod_circle_white {
    border: 1px solid white;
}

.prod_circle svg {
    display: block;
    fill: #C90;
}


.slider-line {
    transition: transform 0.5s ease-in-out;
    /* Adjust the duration and easing as needed */
}

.add_to_cart_btn {
    background: #C90;
    padding: 14px 44px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 9px;
}

.add_to_cart_btn button {
    background: none;
    border: none;
    font-family: 'Mulish', sans-serif;
    color: var(--white);
    font-size: 80%;
    font-weight: 400;
}

.product_text h4 {
    margin-top: 16px;
    margin-left: 17px;
    font-size: 90%;
    font-weight: 700;
}

.product_text p {
    color: #828282;
    font-size: 90%;
    font-weight: 300;
    margin-top: 0;
    position: absolute;
    left: 20px;
    bottom: 20px;
}

.product_text p svg {
    fill: #828282;
}

.market_container {
    display: flex;
    justify-content: space-between;
    gap: 100px;
    margin-top: 103px;
}

.load_more {
    padding: 10px 15px;
    border: 1px solid #C90;
    background: none;
    font-size: 100%;
    font-weight: 500;
    width: 200px;
    display: block;
    margin-top: 40px;
    margin-left: auto;
    margin-right: auto;
}


/* Cart */
.cart_container {
    margin-top: 86px;
    display: flex;
    gap: 95px;
}

.cart_products h3 {
    font-size: 80%;
    font-weight: 600;
    border-bottom: 1px solid #E7E7E7;
    max-width: 674px;
    padding-bottom: 16px;
}

.cart_product {
    display: flex;
    gap: 27px;
    margin-top: 22px;
    max-width: 674px;
    border-bottom: 1px solid #E7E7E7;
    padding-bottom: 23px;
}

.cart_prod_img {
    min-width: 231px;
    height: 180px;
}

.cart_prod_img img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.cart_info_top_left h2 {
    font-size: 90%;
    font-weight: 700;
}

.cart_info_top_left p {
    font-size: 80%;
    font-weight: 500;
    margin-top: 8px;
}

.cart_info_top {
    display: flex;
    align-items: center;
    gap: 168px;
    justify-content: space-between;
}

.cart_info_top img {
    display: block;
    margin-top: -20px;
}

.cart_info_bottom {
    display: flex;
    gap: 156px;
    margin-top: 85px;
}

.counter {
    border: 1px solid #000;
    background: #FFF;
    width: 98px;
    height: 43px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-left: 8px;
    padding-right: 8px;
}

.counter_two {
    margin-top: 25px;
}

.counter button {
    background: none;
    border: none;
    outline: none;
    font-size: 80%;
    font-weight: 700;
}

.counter span {
    font-size: 80%;
    font-weight: 700;
}

.cart_info_bottom p {
    font-size: 80%;
    font-weight: 400;
    margin-top: 10px;
}

.cart_info_bottom p span {
    font-weight: 500;
    margin-left: 4px;
}

.total_sum_container {
    max-width: 401px;
    height: 290px;
    border: 1px solid #000;
    background: #FBFBFB;
    padding: 0 20px 27px 20px;
    margin-top: 40px;
}

.total_sum_top {
    display: flex;
    justify-content: space-between;
    margin-top: 27px;
}

.total_sum_top p {
    font-size: 90%;
    font-weight: 300;
}

.total_sum_top span {
    font-size: 80%;
    font-weight: 500;
}

.sum_line {
    max-width: 360px;
    height: 1px;
    background: rgba(0, 0, 0, 0.20);
    margin-top: 27px;
}

.total_sum_container button {
    display: block;
    max-width: 360px;
    margin-left: auto;
    margin-right: auto;
    padding: 15px 100px;
    background: #C90;
    box-shadow: 0px 0px 9px 0px rgba(0, 0, 0, 0.18);
    border: none;
    margin-top: 35px;
    color: var(--white);
    font-size: 80%;
    font-weight: 800;
}

.card_prod_2 {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 17px;
    border-bottom: 1px solid #E7E7E7;
    padding-bottom: 18px;
}

.card_prod_left {
    display: flex;
    gap: 43px;
    align-items: center;
}

.card_prod_left img {
    margin-left: 23px;
}

.card_prod_left h4 {
    font-size: 90%;
    font-weight: 700;
}

.card_prod_left p {
    font-size: 80%;
    font-weight: 400;
    margin-top: 12px;
}

.card_prod_2 p span {
    font-size: 90%;
    font-weight: 700;
}


/* Usluqi */

.drop_down_container {
    margin-top: 89px;
    border-top: 1px solid #E7E7E7;
}

.counting_card {
    border-bottom: 1px solid #E7E7E7;
    padding-bottom: 20px;
    padding-left: 20px;
    padding-right: 20px;
}

.counting_card_top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 18px;
}

.counting_card_top h3 {
    font-size: 90%;
    font-weight: 900;
}


.add_to_cart_two {
    width: 224px;
    height: 37px;
    border: 1px solid #C90;
    background: #C90;
    box-shadow: 0px 0px 9px 0px rgba(0, 0, 0, 0.18);
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 2px;
}

.add_to_cart_two button {
    background: none;
    border: none;
    outline: none;
    font-size: 70%;
    color: white;
    font-weight: 800;
}

.counting_card_top_right {
    display: flex;
    gap: 39px;
    align-items: center;
}

.counting_card p:nth-child(1) {
    font-size: 90%;
    font-weight: 700;
}

.counting_card p:nth-child(2) {
    margin-top: 5px;
    font-size: 70%;
    font-weight: 300;
}

/* Product View Card */



.card_slider {
    width: 576px;
    height: 448px;
    overflow: hidden;
    position: relative;
}

.card_slider_line {
    display: flex;
    height: 448px;
    width: 2304px;
}

.card_slider_line_img {
    min-width: 576px;
    height: 448px;
    text-align: center;
    background-color: white;
}

.card_slider_line_img img {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.circle_two {
    width: 41px;
    height: 41px;
    border: 1px solid #c90;
    border-radius: 100%;
    position: absolute;
    top: 10px;
    right: 10px;
    padding: 8px;
    text-align: center;
}

.card_left_btn {
    position: absolute;
    left: 10px;
    top: 22vh;
    border: 1px solid #c90;
    border-radius: 50%;
}

.card_right_btn {
    position: absolute;
    right: 10px;
    top: 22vh;
    border: 1px solid #c90;
    border-radius: 50%;
}

.prod_img {
    width: 98px;
    height: 76px;
    background-color: #0000009b;
}

.prod_img img {
    width: 100%;
    height: 100%;
}

.prod_imgs {
    display: flex;
    gap: 6px;
    margin-top: 15px;
    margin-left: 15px;
}

.product_card_text {
    border-left: 2px solid #D1D1D1;
    margin-left: 15px;
    padding-left: 20px;
    margin-top: 25px;
}

.product_card_text h3 {
    font-size: 90%;
    font-weight: 600;
}

.product_card_text p {
    font-size: 60%;
    font-weight: 300;
    /* line-height: 20px; */
    padding-right: 20px;
}


/* Slider */

.slider_container {
    max-width: 1170px;
    margin: 30px auto;
    padding: 30px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 30px;
    margin-top: 150px;
}

.slider {
    width: 1100px;
    height: 450px;
    overflow: hidden;
}

.slider-line {
    width: 6600px;
    height: 450px;
    display: flex;
    background-color: #F6EEE1;
    position: relative;
    left: 0;
    transition: all ease 1s;
}


.slider_content_container {
    width: 1000px;
    height: 450px;
    background-image: url('../img/v933-audi-socialmedia-04\ 1.png');
    background-repeat: no-repeat;
    background-position: left;
    background-size: cover;
    position: relative;
    padding: 30px;
}

.slider_content_container h4 {
    max-width: 800px;
    color: var(--brown);
    font-size: 175%;
    font-weight: 900;
}

.slider_content_container p {
    max-width: 800px;
    font-size: 100%;
    font-weight: 400;
    margin-top: 30px;
}

.slider_content_container button {
    padding: 14px 28px;
    background-color: var(--brown);
    border-radius: 3px;
    border: 1px solid #96593C;
    /* margin-top: 15vh; */
    color: var(--white);
    font-size: 80%;
    font-weight: 600;
    font-family: 'Mulish', sans-serif;
    position: absolute;
    bottom: 30px;
}

.slider_left_btn,
.slider_right_btn {
    width: 58px;
    height: 55px;
    border: 1px solid var(--brown);
    border-radius: 50%;
    justify-content: center;
    display: flex;
    align-items: center;
}

.slider_left_btn,
.slider_right_btn i {
    color: var(--brown);
}


/* Galery */
.galery_container {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(370px, 1fr));
    gap: 30px;
    margin-top: 30px;
}

.galery_card {
    max-width: 370px;
    text-align: center;
    max-height: 370px;
    background: #FFF;
    box-shadow: 0px 0px 9px 0px rgba(0, 0, 0, 0.18);
    border-radius: 20px;
    position: relative;
}

.section_title {
    margin-top: 30px;
    font-size: 150%;
    color: #C90;
    font-weight: 900;
    font-family: var(--cormorant);
}

.galery_card {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    min-width: 370px;
    max-height: 370px;
    background: #FFF;
    box-shadow: 0px 0px 9px 0px rgba(0, 0, 0, 0.18);
    border-radius: 20px;
    position: relative;
}

.card_content {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.galery_card h4 {
    max-width: 80%;
    color: var(--white);
    font-size: 140%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-weight: 400;
}

.galery_card img {
    width: 100%;
    height: 370px;
    object-fit: cover;
    box-shadow: 0px 0px 9px 0px rgba(0, 0, 0, 0.18);
    border-radius: 20px;
}


/* CheckOut-MoneyCode */

.checkOut_container {
    max-width: 575px;
    height: auto;
    padding: 30px 33px 24px 33px;
    border-radius: 9px;
    background: #FFF;
    box-shadow: 0px 0px 9px 0px rgba(0, 0, 0, 0.18);
    margin-left: auto;
    margin-right: auto;
    margin-top: 60px;
}

.checkOut_container span{
    font-size: 60%; 
}

.checkOut_container h3 {
    font-size: 80%;
    font-weight: 600;
    margin-bottom: 20px;
}

.checkOut_inputs{
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin-top: 20px;
}
.cardNo {
    width: 100%;
    border-radius: 3px;
    border: 0.5px solid #838383;
    box-shadow: 0px 0px 9px 0px rgba(255, 255, 255, 0.44);
    padding: 12px 30px 12px 24px;
    /* margin-top: 45px; */
    font-size: 80%;
    font-weight: 300;
}



.oplate_btn {
    display: block;
    padding: 12px 16px;
    border-radius: 3px;
    background: #C90;
    border: none;
    color: var(--white);
    font-size: 80%;
    font-weight: 600;
    font-family: 'Mulish', sans-serif;
}

.birthDay_info_container {
    max-width: 971px;
    padding: 37px 33px;
    border-radius: 9px;
    background: #FFF;
    box-shadow: 0px 0px 9px 0px rgba(0, 0, 0, 0.18);
    margin-left: auto;
    margin-right: auto;
    display: flex;
    gap: 38px;
    align-items: center;
    margin-top: 60px;
}

.birthDay_info_container input:nth-child(1) {
    width: 442px;
    height: 47px;
    border-radius: 3px;
    border: 0.5px solid #838383;
    box-shadow: 0px 0px 9px 0px rgba(255, 255, 255, 0.44);
    padding-left: 24px;
    outline: none;
}

.birthDay_info_container input:nth-child(2) {
    width: 259px;
    height: 47px;
    border-radius: 3px;
    border: 0.5px solid #838383;
    box-shadow: 0px 0px 9px 0px rgba(255, 255, 255, 0.44);
    padding-left: 24px;
    outline: none;
}

.birthDay_info_container button {
    padding: 14px 22px;
    border-radius: 3px;
    background-color: #c90;
    border: none;
    color: var(--white);
    font-size: 80%;
    font-weight: 600;
    font-family: 'Mulish', sans-serif;

}

.numbers_container {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(375px, 1fr));
    gap: 20px;
    margin-top: 55px;
}

.number_card {
    min-width: 377px;
    height: auto;
    border-radius: 6px;
    background: #FFF;
    box-shadow: 0px 0px 9px 0px rgba(0, 0, 0, 0.18);
    padding: 18px;
}

.number_card h3 {
    font-size: 220%;
    font-weight: 800;
    color: #C90;
    /* margin-top: 30px; */
}
.number_card h4{
    margin-top: 30px;
    font-size: 120%;

}

.number_card ul li{
    /* margin-top: 12px; */
}
.number_card p {
    font-size: 90%;
    font-weight: 400;
    margin-top: 20px;
    /* line-height: 28px; */
}

.text_container {
    max-width: 1170px;
    padding: 30px;
    background: #FFF;
    box-shadow: 0px 0px 9px 0px rgba(0, 0, 0, 0.18);
    height: auto;
    margin-top: 30px;
    border-radius: 25px;
}

.text_container p {
    font-size: 90%;
    font-weight: 400;
    /* line-height: 30px; */
}

.text_container p:nth-child(2) {
    margin-top: 15px;
    font-weight: 800;
}

.rashifrovki p:nth-child(2) {
    font-weight: 400;
    margin-top: 0;
}

.rashifrovki p span {
    font-size: 250%;
    color: #C90;
    font-family: var(--cormorant);
}

.text_container p:nth-child(4) {
    margin-top: 15px;
}

.text_container p:nth-child(5) {
    margin-top: 6px;
}

.text_container p:nth-child(6) {
    margin-top: 10px;
    font-weight: 800;
}

.rashifrovki p:nth-child(3) {
    font-weight: 400;
    margin-top: 0;
}

.text_container h2 {
    text-align: center;
    margin-top: 10px;
    font-size: 150%;
    color: #525252;
}

.bracelet_imgs_container {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(177px, 1fr));
    gap: 20px;
    margin-top: 58px;
}

.img_card {
    width: 177px;
    height: 177px;
    background: #FFF;
    box-shadow: 0px 0px 9px 0px rgba(0, 0, 0, 0.18);
}

.img_card img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.section_sub_title {
    text-align: center;
    margin-top: 20px;
    font-size: 90%;
    font-weight: 400;
    /* line-height: 45px; */
}

.zaprosi {
    margin-top: 20px;
    font-size: 90%;
    font-weight: 400;
    /* line-height: 25px; */
    margin-left: 20px;
}

.textarea {
    width: 1170px;
    height: 282px;
    border-radius: 9px;
    border: 0.5px solid #8F8F8F;
    background: #F6F6F6;
    margin-top: 67px;
    padding: 24px;
    color: #B9B9B9;
    font-size: 100%;
    font-weight: 500;
}

.send {
    display: block;
    margin-left: auto;
    padding: 12px 24px;
    border-radius: 3px;
    border: 1px solid #8F8F8F;
    background: none;
    color: #8F8F8F;
    font-size: 80%;
    font-weight: 600;
    font-family: 'Mulish', sans-serif;
    margin-top: 24px;
}

.num_code_img_container {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(158px, 1fr));
    gap: 10px;
    margin-top: 20px;
}

.num_code_img_card img {
    width: 100%;
    height: 158px;
    object-fit: cover;
    border-radius: 20px;
    box-shadow: 0px 0px 9px 0px rgba(0, 0, 0, 0.18);
    object-position: center;
}

.title_2 {
    text-align: start;
    font-weight: 900;
    font-size: 120%;
}

/* confident */

.confidentalnost p {
    font-size: 80%;
    font-weight: 400;
}

.confidentalnost {
    margin-top: 60px;
    border-radius: 9px;
    background: #FFF;
    box-shadow: 0px 0px 9px 0px rgba(0, 0, 0, 0.18);
    padding: 25px;
}

.confidentalnost label {
    font-size: 90%;
    margin-top: 30px;
    display: block;
}


/* ProductView.css */

.product-view-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
    background-color: rgba(0, 0, 0, 0.338);
}

.product-view-container {
    width: 100%;
    border-radius: 8px;
    background: #FFF;
    max-width: 576px;
    padding-bottom: 25px;
    max-height: 70vh;
    overflow: auto;
}

.product-view-close {
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
}


::-webkit-scrollbar {
    width: 0px;
    height: 0px;
}

::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

::-webkit-scrollbar-thumb {
    background-color: darkgrey;
    outline: 1px solid slategrey;
}


.uslovieUsluq {
    display: flex;
    gap: 20px;
    align-items: start;
    background: #FFF;
    box-shadow: 0px 0px 9px 0px rgba(0, 0, 0, 0.18);
    padding: 25px;
    border-radius: 25px;
    margin-top: 40px;
}

.uslovieUsluq p {
    font-size: 90%;
}

.uslovieUsluq input {
    display: block;
    margin-top: 6px;
}

/* Form */

.form_container {
    margin-left: auto;
    margin-right: auto;
    max-width: 510px;
    height: auto;
    display: flex;
    flex-direction: column;
    border-radius: 9px;
    background: #FFF;
    box-shadow: 0px 0px 9px 0px rgba(0, 0, 0, 0.18);
    padding: 25px 33px 25px 33px;
    margin-top: 95px;
}

.form_container input {
    display: block;
    padding: 12px 10px 12px 24px;
    margin-top: 20px;
}

.footer_container input::placeholder {
    color: #838383;
    font-size: 80%;
    font-weight: 300;
}

.form_container button {
    padding: 11px 10px;
    margin-top: 24px;
    display: block;
    color: var(--white);
    background-color: #C90;
    border: none;
    border-radius: 3px;
    font-size: 80%;
    font-weight: 600;
}

.section_sub_heading {
    text-align: center;
    font-size: 100%;
    font-weight: 400;
    /* line-height: 40px; */
}


.usloviya_container h3 {
    margin-top: 30px;
    font-size: 110%;
    color: #6F6F6F;
    font-weight: 900;
}

.usloviya_container p {
    margin-top: 8px;
    font-size: 80%;
    font-weight: 500;
    /* line-height: 25px; */
    text-align: justify;
}

.usloviya_cont_two {
    text-align: center;
}

.usloviya_cont_two p {
    text-align: center;
}

.notChekedBtn {
    background-color: #525252;
}

.slider_content {
    max-width: 760px;
}


/* pagenation */

.pagination {
    width: 75%;
    margin-left: auto;
    margin-top: 60px;
    display: flex;
    align-items: center;
    gap: 40px;
    justify-content: center;
}

.pagination button {
    padding: 10px 10px;
    border: 2px solid #C90;
    background: none;
}

.pagination button i {
    color: #c90;
    font-size: 22px;
}

.pagination span {
    font-size: 120%;
    font-weight: 500;
}


/* Loading */
.loading {
    transform: translate(45%, 50%);

}

.lds-dual-ring {
    display: inline-block;
    width: 80px;
    height: 80px;
    transform: translate(50%, 50%);

}

.lds-dual-ring:after {
    content: " ";
    display: block;
    width: 64px;
    height: 64px;
    margin: 8px;
    border-radius: 50%;
    border: 6px solid #fff;
    border-color: #fff transparent #fff transparent;
    animation: lds-dual-ring 1.2s linear infinite;

}

@keyframes lds-dual-ring {
    0% {
        transform: rotate(0deg);
        /* transform: translate(50%, 50%); */
    }

    100% {
        transform: rotate(360deg);
        /* transform: translate(50%, 50%); */

    }
}


.rospatent {
    max-width: 1170px;
    /* height: 80vh; */
    margin-left: auto;
    margin-right: auto;
}

.rospatent img {
    width: 100%;
}

.text {
    max-width: 1170px;
    margin-left: auto;
    margin-right: auto;
}

.text img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}


#filtersLabel {
    display: none;
}

/* SliderABOUT */

#slider {
    position: relative;
    overflow: hidden;
    margin: 20px auto 0 auto;
    border-radius: 4px;
}

#slider ul {
    position: relative;
    margin: 0;
    padding: 0;
    height: 200px;
    list-style: none;
}

#slider ul li {
    position: relative;
    display: block;
    float: left;
    margin: 0;
    padding: 0;
    width: 500px;
    height: 300px;
    background: #fff;
    text-align: center;
    /* line-height: 300px; */
}

#slider ul li img {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

a.control_prev,
a.control_next {
    position: absolute;
    top: 40%;
    z-index: 999;
    display: block;
    width: auto;
    height: auto;
    color: #C90;
    text-decoration: none;
    font-weight: 600;
    font-size: 38px;
    opacity: 0.8;
    cursor: pointer;
}

a.control_prev:hover,
a.control_next:hover {
    opacity: 1;
    -webkit-transition: all 0.2s ease;
}

a.control_prev {
    border-radius: 0 2px 2px 0;
}

a.control_next {
    right: 0;
    border-radius: 2px 0 0 2px;
}

.slider_option {
    position: relative;
    margin: 10px auto;
    width: 160px;
    font-size: 18px;
}

#trueBtn {
    position: absolute;
    left: 20px;
}



.oferta{
    max-width: 1100px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 30px;
    display: flex;
    flex-direction: column;
    gap: 30px;
}

.oferta img{
    width: 100%;
    height: 100%;
    object-fit: cover;
}


/* certs */


.grid_certificates {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));
    gap: 20px;
    margin-top: 40px;
}

.certificate_card {
    border-radius: 6px;
    background: #FFF;
    box-shadow: 0px 0px 9px 0px rgba(0, 0, 0, 0.17);
}

.certificate_card img {
    width: 100%;
    height: 300px;
    object-fit: contain ;
}


.formText{
    text-align: start;
    margin-left: 10%;
    margin-top: 20px;
}